import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const Background = ({ fluid, className, style, children }) =>
    fluid ? (
        <BackgroundImage
            {...{ fluid, className }}
            style={{ position: 'relative', ...style }}
        >
            {children}
        </BackgroundImage>
    ) : (
        <div {...{ className, style }}>{children}</div>
    )

export default Background
