import React from "react"

import useCountdown from "../../utils/useCountdown"

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(new Date(targetDate))

  return (
    <div className="mx-auto flex flex-col justify-center items-center border-2 py-2 w-[95%] sm:w-[80%] xl:w-3/5 2xl:w-1/2 rounded border-blue shadow-blue relative">
      <div className="w-16 h-1 absolute rounded-sm shadow-blue -top-1 right-16 bg-lightblue" />
      <div className="w-16 h-1 absolute rounded-sm shadow-blue -bottom-1 left-16 bg-lightblue" />
      <div
        className={`w-6 h-6 absolute -top-1 -right-1 border-t-4 border-r-4 border-lightblue rounded transition-all`}
      />
      <div
        className={`w-6 h-6 absolute -left-1 -bottom-1 border-l-4 border-b-4 border-lightblue rounded transition-all `}
      />
      <h2 className=" text-lightblue uppercase italic text-4xl xl:text-5xl blue-text-glow text-stroke-blue font-semibold">
        {days}:{hours}:{minutes}:{seconds}
      </h2>
    </div>
  )
}

export default CountdownTimer
