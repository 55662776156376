import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { useBrowserState } from "@context"

import dotLinesPurple04 from "@images/animations/dot-lines/purple-04.json"
import arrowUp from "@images/global/arrow-up.svg"

import Background from "@components/Background"
import Lottie from "@components/Lottie"
import Link from "@components/Link"
import SVG from "@components/SVG"

import "./style.scss"

const CharacterHero = ({
  allCharacters = [],
  character,
  backgroundImage,
  locale,
  scrollToBladeId,
}) => {
  const { isSafari, isIOS } = useBrowserState()
  const _characters = allCharacters?.filter(
    ({ node: { comingSoon } }) => comingSoon === false
  )
  const [currentIndex, setCurrentIndex] = useState(
    _characters.findIndex(({ node: { id } }) => id === character?.id)
  )

  const prevIndex =
    currentIndex === 0 ? _characters.length - 1 : currentIndex - 1
  const nextIndex = currentIndex + 2 > _characters.length ? 0 : currentIndex + 1

  return (
    <Background
      fluid={backgroundImage?.fluid}
      className={`character-hero default flex flex-col items-center justify-center w-full py-20 md:py-32 relative bg-darkgrey3 bg-cover bg-bottom bg-no-repeat text-center`}
      style={{ position: "relative" }}
    >
      <div className="container mx-auto" id={scrollToBladeId}>
        <h1 className="hidden">{character?.name}</h1>
        <div className="flex flex-col-reverse lg:flex-row items-center justify-center w-full">
          {isIOS || isSafari ? (
            <GatsbyImage
              image={getImage(character?.animationFallbackImage)}
              className="inline-block w-full max-w-2xl h-auto -mb-20 md:-mb-56"
            />
          ) : (
            <video
              src={character?.animation?.url}
              loop
              muted
              autoPlay
              playsInline
              className="inline-block w-full max-w-2xl h-auto -mb-20 md:-mb-56"
            ></video>
          )}

          {character?.logo ? (
            <div className="w-1/3">
              <div className="flex flex-col items-center justify-center">
                <Link
                  to={`${
                    locale === "en-GB"
                      ? `/characters/${_characters?.[prevIndex]?.node?.slug}`
                      : `/${locale?.toLowerCase()}/characters/${
                          _characters?.[prevIndex]?.node?.slug
                        }`
                  }`}
                  type="button"
                >
                  <SVG
                    src={arrowUp}
                    className="w-12 md:w-16 h-auto mb-4 md:mb-8 text-orange3 hover:text-white"
                  />
                </Link>
                <GatsbyImage
                  image={getImage(character?.logo)}
                  className="w-80 max-w-full h-auto"
                />
                <Link
                  to={`${
                    locale === "en-GB"
                      ? `/characters/${_characters?.[nextIndex]?.node?.slug}`
                      : `/${locale?.toLowerCase()}/characters/${
                          _characters?.[nextIndex]?.node?.slug
                        }`
                  }`}
                  type="button"
                >
                  <SVG
                    src={arrowUp}
                    className="w-12 md:w-16 h-auto mt-4 md:mt-8 transform rotate-180 origin-center text-orange3 hover:text-white"
                  />
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Lottie
        animation={dotLinesPurple04}
        className="w-8 h-auto absolute bottom-0 right-8 transform -translate-y-8"
        loop={true}
      />
    </Background>
  )
}

export default CharacterHero
