import React from 'react'
import BackgroundImage from 'gatsby-background-image'

import { useLightboxState } from '@context'
import cx from 'classnames'
import dotLinesPurple04 from '@images/animations/dot-lines/purple-04.json'

import { Play as PlayButton } from '@components/Buttons'
import Lottie from '@components/Lottie'

import './style.scss'

const VideoHero = ({
    heading,
    description,
    mobileBackgroundImage,
    backgroundImage,
    video,
    scrollToBladeId,
    hidePlayIcon,
    playIconText,
}) => {
    const { setLightboxVideo, setLightboxOpen } = useLightboxState()

    const handleVideoClick = () => {
        setLightboxVideo(video)
        setLightboxOpen(true)
    }

    return (
        <div
            id={scrollToBladeId}
            className={cx(
                `video-hero default flex flex-col items-center justify-center w-full h-screen-4/5 relative bg-darkgrey3 bg-cover bg-center bg-no-repeat text-center`,
                {
                    '!h-screen': hidePlayIcon,
                }
            )}
        >
            <div className="md:hidden w-full h-full absolute top-0 left-0">
                <BackgroundImage
                    fluid={mobileBackgroundImage?.fluid}
                    style={{ position: 'absolute' }}
                    className="w-full h-full absolute top-0 left-0"
                />
            </div>
            <div className="hidden md:block w-full h-full absolute top-0 left-0">
                <BackgroundImage
                    fluid={backgroundImage?.fluid}
                    style={{ position: 'absolute' }}
                    className="w-full h-full absolute top-0 left-0"
                />
            </div>
            {heading ? (
                <h1
                    className="outline-heading mb-12 md:mb-16 text-4xl md:text-5xl lg:text-6xl font-display tracking-wide font-bold text-white uppercase italic z-20"
                    data-text={heading}
                >
                    <span className="outline-heading__inner">{heading}</span>
                </h1>
            ) : null}

            <PlayButton className="relative" onClick={handleVideoClick} />

            {description ? (
                <div
                    className="video-hero__description max-w-xs mx-auto mt-4 md:mt-8 text-xl md:text-2xl font-display tracking-wide font-bold text-white italic z-20"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            ) : null}
            <Lottie
                animation={dotLinesPurple04}
                className="w-8 h-auto absolute bottom-0 right-8 transform -translate-y-8"
                loop={true}
            />
        </div>
    )
}

export default VideoHero
