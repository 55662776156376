import React, { useRef } from 'react'
import lottie from 'lottie-web'
import { useEffect } from 'react'

const Lottie = ({
    animation,
    loop = false,
    autoplay = true,
    preserveAspectRatio = 'none',
    className,
    delay = 100,
}) => {
    const containerRef = useRef(null)

    useEffect(() => {
        if (containerRef?.current) {
            setTimeout(() => {
                lottie.loadAnimation({
                    container: containerRef?.current,
                    renderer: 'svg',
                    loop,
                    autoplay,
                    animationData: animation,
                    rendererSettings: {
                        preserveAspectRatio,
                    },
                })
            }, delay)
        }
    }, [])

    return <div ref={containerRef} {...{ className }} />
}

export default Lottie
