import { format, parseISO } from "date-fns"
import React from "react"

import Background from "@components/Background"
import {
  Facebook as FacebookShare,
  Reddit as RedditShare,
  Twitter as TwitterShare,
} from "@components/Share"
import { useBrowserState } from "@context"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import functions from "@functions"

import { GatsbyImage } from "gatsby-plugin-image"
import "./style.scss"

const { getDateFormat } = functions

const PostHero = ({
  post,
  heading,
  description,
  backgroundImage,
  showSocialButtons,
  locale,
  scrollToBladeId,
  animation,
  animationFallbackImage,
}) => {
  const { isSafari, isIOS } = useBrowserState()

  const tags = JSON.parse(post?.tags)

  const background = convertToBgImage(
    getImage(post.featuredImage.gatsbyImageData)
  )

  if (post && post.category && post.category.toLowerCase() === "arena") {
    return (
      <>
        <div
          id={scrollToBladeId}
          className=" relative bg-lightgrey2 text-center lg:text-left h-[30vh] max-h-[600px]"
        >
          <div className=" mx-auto lg:pl-20 xl:pl-32 ">
            <div className="flex flex-col lg:flex-row  relative max-h-[500px] ">
              <div className=" post-hero__panel static lg:relative w-full lg:w-96 md:px-10 py-12 pb-20 px-8 lg:pl-0 lg:pr-8 pt-40 bg-lightgrey1 lg:bg-white">
                <div className="post-hero__line w-px absolute bottom-0 left-8 lg:left-0 bg-darkgrey1 bg-opacity-20 z-30 pointer-events-none" />
                <div className="flex flex-col items-center lg:items-start max-w-lg lg:max-w-full mx-auto lg:mx-0 relative z-30">
                  {post && post.category ? (
                    <span className="post-hero__category inline-flex items-center justify-center h-8 mb-8 px-10 bg-orange3 font-display font-light text-lg text-regalblue uppercase">
                      {post.category}
                    </span>
                  ) : null}
                  {post?.publishedAt ? (
                    <span className="block font-semibold text-lg text-orange3">
                      {format(
                        parseISO(post?.publishedAt),
                        getDateFormat(locale)
                      )}
                    </span>
                  ) : null}
                  <h2
                    className="outline-heading orange3 mt-4 mb-4 md:mb-8 relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey1 font-display font-bold tracking-wide italic uppercase"
                    data-text={heading || post?.title}
                  >
                    <span className="outline-heading__inner">
                      {heading || post?.title}
                    </span>
                  </h2>
                  {showSocialButtons ? (
                    <>
                      <span className="mt-8 font-display font-light text-xl text-darkgrey1 uppercase italic">
                        Share
                      </span>
                      <div className="flex items-center justify-center md:justify-start mt-4 -ml-4 text-orange2">
                        {typeof window !== "undefined" ? (
                          <TwitterShare url={window?.location?.href} />
                        ) : null}
                        {typeof window !== "undefined" ? (
                          <FacebookShare url={window?.location?.href} />
                        ) : null}
                        {typeof window !== "undefined" ? (
                          <RedditShare url={window?.location?.href} />
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className=" lg:flex-1 w-full h-120 lg:h-auto lg:w-auto relative text-center">
                <BackgroundImage
                  {...background}
                  className="w-full h-full relative z-0"
                  preserveStackingContext
                ></BackgroundImage>
                {post && post.animation && post.animationFallbackImage && (
                  <div className=" mx-auto absolute bottom-0 lg:top-20 w-full">
                    <div className="flex items-center justify-center w-full">
                      {isIOS || isSafari ? (
                        <GatsbyImage
                          image={getImage(post?.animationFallbackImage)}
                          className="inline-block w-auto max-w-2xl h-full -mb-56"
                        />
                      ) : (
                        <video
                          src={post?.animation?.url}
                          loop
                          muted
                          autoPlay
                          playsInline
                          className="inline-block w-auto max-w-2xl  h-full -mb-56"
                        ></video>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <Background
          fluid={backgroundImage?.fluid || post?.featuredImage?.fluid}
          className="flex flex-col items-center justify-center w-full h-screen-4/5 relative bg-darkgrey3 bg-cover bg-center bg-no-repeat"
          style={{ position: "relative" }}
        />
        <div className="post-hero relative bg-lightgrey2 text-center lg:text-left">
          <div className="lg:container mx-auto lg:px-20 xl:px-32">
            <div className="flex flex-col lg:flex-row items-center relative">
              <div className="post-hero__panel static lg:relative w-full lg:w-96 md:px-10 py-12 pb-20 px-8 lg:pl-0 lg:pr-8 bg-lightgrey1 lg:bg-white">
                <div className="post-hero__line w-px absolute bottom-0 left-8 lg:left-0 bg-darkgrey1 bg-opacity-20 z-30 pointer-events-none" />
                <div className="flex flex-col items-center lg:items-start max-w-lg lg:max-w-full mx-auto lg:mx-0 relative z-30">
                  {tags?.length > 0 ? (
                    <span className="post-hero__category inline-flex items-center justify-center h-8 mb-8 px-10 bg-orange3 font-display font-light text-lg text-regalblue uppercase">
                      {tags?.[0]}
                    </span>
                  ) : null}
                  {post?.publishedAt ? (
                    <span className="block font-semibold text-lg text-orange3">
                      {format(
                        parseISO(post?.publishedAt),
                        getDateFormat(locale)
                      )}
                    </span>
                  ) : null}
                  <h2
                    className="outline-heading orange3 mt-4 mb-4 md:mb-8 relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey1 font-display font-bold tracking-wide italic uppercase"
                    data-text={heading || post?.title}
                  >
                    <span className="outline-heading__inner">
                      {heading || post?.title}
                    </span>
                  </h2>
                  {showSocialButtons ? (
                    <>
                      <span className="mt-8 font-display font-light text-xl text-darkgrey1 uppercase italic">
                        Share
                      </span>
                      <div className="flex items-center justify-center md:justify-start mt-4 -ml-4 text-orange2">
                        {typeof window !== "undefined" ? (
                          <TwitterShare url={window?.location?.href} />
                        ) : null}
                        {typeof window !== "undefined" ? (
                          <FacebookShare url={window?.location?.href} />
                        ) : null}
                        {typeof window !== "undefined" ? (
                          <RedditShare url={window?.location?.href} />
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="post-hero__content flex-1 w-full lg:w-auto px-8 lg:px-0 py-12 md:py-20 lg:pl-20 relative text-center">
                <div className="inline-block w-full max-w-xl mx-auto lg:mx-0 font-display text-2xl md:text-3xl text-center lg:text-left text-darkgrey1 uppercase italic">
                  {description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PostHero
