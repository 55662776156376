import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import dotLinesPurple04 from '@images/animations/dot-lines/purple-04.json'

import Background from '@components/Background'
import Lottie from '@components/Lottie'

import './style.scss'

const TextHero = ({
    heading,
    description,
    backgroundImage,
    leftImage,
    rightImage,
    small = false,
    scrollToBladeId,
}) => {
    const thin = (!description && !heading) || small
    return (
        <Background
            fluid={backgroundImage?.fluid}
            className={`text-hero ${
                thin ? 'thin' : 'default'
            } flex flex-col items-center justify-center w-full ${
                thin ? 'h-screen-3/5' : 'h-screen-4/5'
            } relative bg-darkgrey3 bg-cover bg-bottom bg-no-repeat text-center`}
            style={{ position: 'relative' }}
            id={scrollToBladeId}
        >
            {leftImage ? (
                <GatsbyImage
                    image={getImage(leftImage)}
                    className="hidden md:block w-5/12 xl:w-4/12 h-auto absolute bottom-0 left-0"
                    style={{ position: 'absolute' }}
                />
            ) : null}
            {rightImage ? (
                <GatsbyImage
                    image={getImage(rightImage)}
                    className={`hidden md:block w-5/12 xl:w-1/4 h-auto absolute bottom-0 ${
                        !description && !heading
                            ? 'xl:bottom-16'
                            : 'xl:bottom-0'
                    } right-0`}
                    style={{ position: 'absolute' }}
                />
            ) : null}
            {heading ? (
                <h1
                    className="outline-heading max-w-xl mx-auto mt-12 md:mt-16 text-4xl md:text-5xl lg:text-6xl font-display tracking-wide font-bold text-white uppercase italic z-20"
                    data-text={heading}
                >
                    <span className="outline-heading__inner">{heading}</span>
                </h1>
            ) : null}
            {description ? (
                <div
                    className="video-hero__description max-w-sm mx-auto mt-4 md:mt-8 text-xl font-light tracking-wide text-white z-20"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            ) : null}
            <Lottie
                animation={dotLinesPurple04}
                className="w-8 h-auto absolute bottom-0 right-8 transform -translate-y-8"
                loop={true}
            />
        </Background>
    )
}

export default TextHero
