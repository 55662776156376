import React from "react";

import reddit from "@images/global/reddit.svg";

import SVG from "@components/SVG";

const RedditShare = () => {
  const handleSocialClick = (e) => {
    e.preventDefault();
    const url = e?.currentTarget?.href;
    const w = 600;
    const h = 400;

    if (url) {
      window.open(
        url,
        "_blank",
        `
                scrollbars=yes,
                width=${w}, 
                height=${h}, 
                top=${window?.innerHeight / 2 - h / 2}, 
                left=${window?.innerWidth / 2 - w / 2}
            `
      );
    }
  };

  return (
    <a
      href={`https://www.reddit.com/submit?url=${window?.location?.href}`}
      target="_blank"
      rel="noopener nofollow noreferrer"
      className="flex items-center justify-center font-bold ml-4 relative"
      onClick={handleSocialClick}
    >
      <span className="group block circle-with-tag w-14 h-14 transform hover:rotate-180 rounded-full border-2 border-orange2 transition-all" />
      <SVG
        src={reddit}
        className="w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group-hover:-rotate-180 origin-center transition-all pointer-events-none"
      />
    </a>
  );
};

export default RedditShare;
