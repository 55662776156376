import React from 'react'

import play from '@images/global/play.svg'

import SVG from '@components/SVG'

const getBackgroundColor = (color, modifier) => {
    switch (color) {
        case 'white':
            if (modifier === 'hover') return 'hover:bg-white'
            if (modifier === 'group-hover') return 'group-hover:bg-white'
            return 'bg-white'
        case 'yellow':
            if (modifier === 'hover') return 'hover:bg-yellow'
            if (modifier === 'group-hover') return 'group-hover:bg-yellow'
            return 'bg-yellow'
        case 'orange1':
            if (modifier === 'hover') return 'hover:bg-orange1'
            if (modifier === 'group-hover') return 'group-hover:bg-orange1'
            return 'bg-orange1'
        case 'orange2':
            if (modifier === 'hover') return 'hover:bg-orange2'
            if (modifier === 'group-hover') return 'group-hover:bg-orange2'
            return 'bg-orange2'
        case 'orange3':
            if (modifier === 'hover') return 'hover:bg-orange3'
            if (modifier === 'group-hover') return 'group-hover:bg-orange3'
            return 'bg-orange3'
        default:
            if (modifier === 'hover') return 'hover:bg-blue'
            if (modifier === 'group-hover') return 'group-hover:bg-blue'
            return 'bg-blue'
    }
}

const getBorderClass = (color) => {
    switch (color) {
        case 'white':
            return 'border-white'
        case 'yellow':
            return 'border-yellow'
        case 'orange1':
            return 'border-orange1'
        case 'orange2':
            return 'border-orange2'
        case 'orange3':
            return 'border-orange3'
        default:
            return 'border-blue'
    }
}

const PlayButton = ({ color = 'blue', className, onClick = () => true }) => {
    return (
        <div
            className={`group flex items-center justify-center w-20 h-20 rounded border-2 ${getBorderClass(
                color
            )} cursor-pointer ${className} bg-black bg-opacity-30 group-hover:bg-opacity-100 ${getBackgroundColor(
                color,
                'group-hover'
            )} ${getBackgroundColor(color, 'hover')} transition`}
            {...{ onClick }}
        >
            <div
                className={`w-5 h-5 absolute -top-1 group-hover:top-0 -left-1 group-hover:left-0 border-t-4 border-l-4 ${getBorderClass(
                    color
                )} rounded transition-all group-hover:delay-150`}
            />
            <div
                className={`w-5 h-5 absolute -right-1 group-hover:right-0 -bottom-1 group-hover:bottom-0 border-r-4 border-b-4 ${getBorderClass(
                    color
                )} rounded transition-all group-hover:delay-150`}
            />

            <SVG src={play} className="w-3 h-auto text-white" />
        </div>
    )
}

export default PlayButton
