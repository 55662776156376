import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { format, parseISO } from "date-fns"
import { Link } from "gatsby"

import arrowLeft from "@images/global/arrow-left.svg"
import arrowRight from "@images/global/arrow-right.svg"

import functions from "@functions"

import geometricPatternDark from "@images/animations/geometric-patterns/geometric-pattern-dark.json"
import dotLinesPurple04 from "@images/animations/dot-lines/purple-04.json"

import Lottie from "@components/Lottie"
import SVG from "@components/SVG"

import "./style.scss"

const { getDateFormat } = functions

const Card = ({
  buttonLabel,
  title,
  slug,
  excerpt,
  meta,
  tags: _tags,
  locale,
}) => {
  const tags = JSON.parse(_tags)

  return (
    <>
      {tags?.length > 0 ? (
        <div className="hidden lg:inline-flex news-hero__tag self-end items-center justify-center px-4 pr-20 mb-4 h-8 bg-orange3 text-blue font-display font-light uppercase z-0 select-none">
          {tags?.[0]}
        </div>
      ) : null}
      <span
        className="outline-heading yellow block w-full max-w-md mx-auto lg:mx-0 relative text-4xl lg:text-5xl text-white font-display font-bold tracking-wide tracking-wide italic uppercase select-none"
        data-text={title}
      >
        <span className="outline-heading__inner">{title}</span>
      </span>

      <div className="mt-8">
        <span className="hidden lg:block font-semibold text-xl text-orange3 select-none">
          {format(parseISO(meta?.firstPublishedAt), getDateFormat(locale))}
        </span>
        {excerpt ? (
          <span className="hidden lg:block max-w-xs lg:mt-2 font-light text-lg text-white select-none">
            {excerpt}
          </span>
        ) : null}
        <Link
          to={`/news/${slug}`}
          className={`inline-flex items-center justify-center py-4 px-8 lg:mt-8 bg-transparent hover:bg-blue text-blue hover:text-white hover:scale-110 transform -skew-x-12 border-2 border-blue font-display font-bold text-2xl tracking-wide uppercase italic rounded transition select-none`}
        >
          <span className="skew-x-12">{buttonLabel}</span>
        </Link>
      </div>
    </>
  )
}
const NewsHero = ({
  allPosts = [],
  buttonLabel,
  posts: items = [],
  locale,
  scrollToBladeId,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    let interval = null

    interval = setInterval(() => {
      const nextIndex = currentIndex + 2 > items.length ? 0 : currentIndex + 1

      setCurrentIndex(nextIndex)
    }, 6500)

    return () => clearInterval(interval)
  }, [currentIndex, items, setCurrentIndex])

  const handlePrev = () => {
    setCurrentIndex(currentIndex === 0 ? items.length - 1 : currentIndex - 1)
  }

  const handleNext = () => {
    setCurrentIndex(currentIndex + 2 > items.length ? 0 : currentIndex + 1)
  }

  return items?.length > 0 ? (
    <div
      id={scrollToBladeId}
      className="news-hero flex flex-col items-center justify-center w-full h-screen-4/5 relative bg-regalblue text-center overflow-hidden"
    >
      <Lottie
        animation={geometricPatternDark}
        className="w-double md:w-full lg:w-3/5 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-30 z-0 pointer-events-none select-none overflow-hidden"
        loop={true}
      />

      <div className="w-full lg:container mx-auto py-20 md:py-32 lg:py-48 px-8 lg:px-20 xl:px-32 z-10 relative">
        <div className="flex flex-col-reverse lg:flex-row items-center">
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <div className="pr-8">
              <div className="flex -m-4">
                {items?.map(({ node, ...post }, i) => {
                  const postObj = { ...node, ...post }
                  const { node: _node, ..._post } = allPosts.find(
                    ({ node: { originalId } }) =>
                      originalId === postObj?.originalId
                  )

                  return (
                    <div
                      key={i}
                      className={`flex-none w-full p-4 text-center lg:text-left ${
                        i === currentIndex
                          ? "opacity-100 visible"
                          : "opacity-0 invisible"
                      } transition-all duration-500`}
                      style={{
                        transform: `translate3d(-${currentIndex * 100}%, 0, 0)`,
                      }}
                    >
                      <Card
                        {...{
                          buttonLabel,
                          ..._node,
                          ..._post,
                          locale,
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
            <div className="max-w-lg lg:max-w-full flex items-center justify-between mx-auto lg:mx-0 relative z-20">
              <button type="button" className="mr-8" onClick={handlePrev}>
                <SVG
                  src={arrowLeft}
                  className="w-8 h-auto mt-8 text-orange3 hover:text-white"
                />
              </button>
              <div className="relative w-full pt-9/16">
                {items?.map(({ thumbnail }, i) => (
                  <div
                    className={`w-full h-full absolute top-0 left-0 ${
                      i === currentIndex
                        ? "opacity-100 visible"
                        : "opacity-0 invisible"
                    } transition-all duration-500`}
                  >
                    <GatsbyImage
                      image={thumbnail?.gatsbyImageData}
                      className="news-hero__thumbnail w-full h-full absolute top-0 left-0 transform bg-cover bg-center bg-norepeat"
                      style={{ position: "absolute" }}
                    />
                  </div>
                ))}
              </div>
              <button type="button" className="ml-8" onClick={handleNext}>
                <SVG
                  src={arrowRight}
                  className="w-8 h-auto mt-8 text-orange3 hover:text-white"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Lottie
        animation={dotLinesPurple04}
        className="w-8 h-auto absolute bottom-0 right-8 transform -translate-y-8"
        loop={true}
      />
    </div>
  ) : null
}

export default NewsHero
