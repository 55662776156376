import TextHero from "./Text"
import VideoHero from "./Video"
import SeasonsHero from "./Seasons"
import VideoReleaseHero from "./VideoRelease"
import CharacterHero from "./Character"
import PostHero from "./Post"
import NewsHero from "./News"

export default TextHero
export {
  VideoHero,
  VideoReleaseHero,
  CharacterHero,
  PostHero,
  NewsHero,
  SeasonsHero,
}
