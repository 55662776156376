import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'

import { useLightboxState } from '@context'
import cx from 'classnames'
import dotLinesPurple04 from '@images/animations/dot-lines/purple-04.json'

import { Play as PlayButton } from '@components/Buttons'
import Lottie from '@components/Lottie'

import './style.scss'

const VideoReleaseHero = ({
    backgroundImage,
    mobileBackgroundImage,
    video,
    hidePlayIcon,
    playIconText,
}) => {
    const { setLightboxVideo, setLightboxOpen } = useLightboxState()

    const handleVideoClick = () => {
        setLightboxVideo(video)
        setLightboxOpen(true)
    }

    return (
        <div
            className={cx(
                `video-hero default flex flex-col items-center justify-center w-full h-screen-4/5 lg:h-screen relative bg-darkgrey3 bg-cover bg-center bg-no-repeat text-center`,
                {
                    '!h-screen': hidePlayIcon,
                }
            )}
        >
            <div className="md:hidden w-full h-full absolute top-0 left-0">
                <BackgroundImage
                    fluid={mobileBackgroundImage?.fluid}
                    style={{ position: 'absolute' }}
                    className="w-full h-full absolute top-0 left-0"
                />
            </div>
            <div className="hidden md:block w-full h-full absolute top-0 left-0">
                <BackgroundImage
                    fluid={backgroundImage?.fluid}
                    style={{ position: 'absolute' }}
                    className="w-full h-full absolute top-0 left-0"
                />
            </div>

            <div className="flex flex-col items-center justify-center lg:mb-32 text-center">
                {playIconText ? (
                    <div className="max-w-[10rem] md:max-w-[12rem] mx-auto mb-8 text-center text-white uppercase text-lg md:text-xl relative z-20 tracking-widest">
                        {playIconText}
                    </div>
                ) : null}

                <PlayButton className="relative" onClick={handleVideoClick} />
            </div>

            <Lottie
                animation={dotLinesPurple04}
                className="w-8 h-auto absolute bottom-0 right-8 transform -translate-y-8"
                loop={true}
            />
        </div>
    )
}

export default VideoReleaseHero
