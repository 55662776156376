import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import dotLinesYellow04 from "@images/animations/dot-lines/yellow-04.json"

import Background from "@components/Background"
import Lottie from "@components/Lottie"
import { format, parseISO } from "date-fns"
import functions from "@functions"
import CountdownTimer from "../../CountdownTimer"

import cx from "classnames"

import "./style.scss"

const { getDateFormat } = functions
const SeasonsHero = ({
  heading,
  description,
  backgroundImage,
  startDate,
  endDate,
  centerImage,
  locale,
  showNewSeasonCountdown,
  hasButton,
  buttonExternalUrl,
  buttonLabel,
  buttonColor,
  buttonTextColor,
  newSeasonArrivalDate,
  scrollToBladeId,
}) => {
  const thin = !description && !heading
  return (
    <Background
      fluid={backgroundImage?.fluid}
      className={`text-hero ${
        thin ? "thin" : "default"
      } flex flex-col items-center justify-center gap-6 w-full ${
        thin ? "h-screen-3/5" : "h-screen-4/5"
      } relative bg-darkgrey3 bg-cover bg-bottom bg-no-repeat text-center`}
      style={{ position: "relative" }}
      id={scrollToBladeId}
    >
      <div className="absolute w-full h-full top-0 left-0 bg-black opacity-30"></div>
      {centerImage ? (
        <GatsbyImage
          image={getImage(centerImage)}
          className="w-4/5 sm:w-3/5 md:w-2/5 lg:w-1/4 h-auto max-w-[500px]"
        />
      ) : null}
      {heading ? (
        <h1
          className="outline-heading max-w-screen-2xl mx-auto text-4xl md:text-5xl lg:text-6xl font-display tracking-wide font-bold text-white uppercase italic z-20"
          data-text={heading}
        >
          <span className="outline-heading__inner">{heading}</span>
        </h1>
      ) : null}
      {description ? (
        <div
          className="video-hero__description max-w-sm mx-auto mt-4 md:mt-8 text-xl font-light tracking-wide text-white z-20"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : null}
      {showNewSeasonCountdown && newSeasonArrivalDate ? (
        <div className=" flex flex-col justify-center items-center gap-3">
          <h2 className=" text-lightblue text-center uppercase blue-text-glow italic text-4xl xl:text-5xl text-stroke-blue font-semibold">
            Arriving{" "}
            {format(parseISO(newSeasonArrivalDate), getDateFormat(locale))}
          </h2>
          <CountdownTimer targetDate={newSeasonArrivalDate} />
        </div>
      ) : startDate && endDate ? (
        <h2 className=" text-lightblue text-center uppercase blue-text-glow italic text-4xl xl:text-5xl text-stroke-blue font-semibold">
          {format(parseISO(startDate), getDateFormat(locale))} -{" "}
          {format(parseISO(endDate), getDateFormat(locale))}
        </h2>
      ) : null}
      {hasButton && buttonLabel && buttonExternalUrl ? (
        <div className=" flex flex-col justify-center items-center lg:items-start">
          <a
            target="_blank"
            referrerPolicy="no-referrer"
            href={buttonExternalUrl}
            className={cx(
              "hover:!bg-transparent flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 relative transform -skew-x-12 hover:scale-105 font-display font-bold text-2xl text-white border-2 tracking-wide uppercase italic rounded z-30 transition",
              {
                "hover:text-blue border-blue": !buttonColor,
                "text-black hover:text-white": buttonTextColor === "dark",
                "text-white": buttonTextColor === "light",
              }
            )}
            style={{
              borderColor: buttonColor.hex ? buttonColor.hex : undefined,
              backgroundColor: buttonColor.hex ? buttonColor.hex : undefined,
            }}
          >
            <span className="transform skew-x-12">{buttonLabel}</span>
          </a>
        </div>
      ) : null}
      <Lottie
        animation={dotLinesYellow04}
        className="w-8 h-auto absolute bottom-0 right-8 transform -translate-y-8"
        loop={true}
      />
    </Background>
  )
}

export default SeasonsHero
