import languages from '../config/languages'

export default {
    linkResolver: (doc) => {
        switch (doc.type) {
            case 'page': {
                return doc.uid === 'home' ? `/` : `/${doc.lang}/${doc.uid}`
            }

            default:
                return '/'
        }
    },

    getSlugFromDocument: ({ uid = 'home', type = 'page' }) => {
        if (uid === 'home') return '/'

        switch (type) {
            case 'page':
                return `/${uid}`
            case 'character':
                return `/characters/${uid}`
            case 'post':
                return `/news/${uid}`
        }
    },

    truncate: (str = '', len = 30) => {
        if (str?.length > len) return `${str?.substring(0, len)}&mldr;`

        return str
    },

    getDateFormat: (locale) => {
        const _locale = locale.toLowerCase()
        return (
            languages?.find(({ code }) => code?.toLowerCase() === _locale)
                ?.dateFormat || 'dd.MM.yyyy'
        )
    },
}
